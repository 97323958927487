/* auth.css */
.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  padding: 2rem;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.login-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 400px;
}

.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auth-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.auth-button.google {
  background-color: #ffffff;
  color: #444;
  border: 1px solid #ddd;
  text-align: center;
  display: flex;
  align-items: center;
}

.auth-button.email {
  background-color: #4285f4;
  color: white;
}
.login-container h1 {
  font-size: 2.5rem; /* Increase font size */
  font-weight: bold; /* Make it bold */
  margin-bottom: 1.5rem; /* Add margin below */
  text-align: center; /* Center the text */
  color: #000000; /* Change color for better visibility */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add a stronger text shadow */
  letter-spacing: 1px; /* Add letter spacing for better readability */
}

.submit-button.sign-in-btn {
  background-color: #4285f4;
  color: white;
  padding: 0.75rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
}

.submit-button.sign-in-btn:hover {
  background-color: #3367d6;
  transform: translateY(-2px);
}

.auth-button.phone {
  background-color: #34a853;
  color: white;
}

.submit-button.send-code-btn {
  background-color: #34a853;
  color: white;
  padding: 0.75rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-button.send-code-btn:hover {
  background-color: #2c9a3a;
  transform: translateY(-2px);
}

.auth-button i {
  font-size: 1.5rem;
  width: 24px;
  text-align: center;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  position: relative;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.auth-form h2 {
  margin: 0 0 1rem 0;
  text-align: center;
  color: #1f2937;
  font-size: 1.5rem;
}

.auth-input {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.auth-input:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.close-button {
  position: absolute;
  width: auto;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6b7280;
  padding: 0.25rem;
  line-height: 1;
}

.close-button:hover {
  color: #1f2937;
}

.error-login-message {
  color: #dc2626;
  text-align: center;
  padding: 0.75rem;
  background-color: #fee2e2;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #4b5563;
}

.loading-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.loading-message .small-text {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
}

.loading-message .loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #4285f4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin:20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* app.css */
.app {
    width: 75%;
    height: 75%;
    min-width: 60%;
    min-height: 60%;
    max-width: 75%;
    max-height: 75%;
    display: grid;
    grid-template-columns: minmax(120px, 10%) 1fr minmax(120px, 10%);
    gap: 1rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
    margin: 2rem auto;
  }
  
  /* Sidebar Styles */
.sidebar,
.sidebar-right {
  padding: 1rem;
  border-right: 1px solid #e5e7eb;
}

.sidebar-right {
  border-right: none;
  border-left: 1px solid #e5e7eb;
}

/* Button Styles */
button {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: white;
  color: #374151;
  text-align: left;
  transition: all 0.2s ease;
  cursor: pointer;
}

button:hover {
  background-color: #f3f4f6;
}

button.active {
  background-color: #2563eb;
  color: white;
  border-color: #2563eb;
}

button.RefreshButton:hover {
  background-color: #fecaca;
  border-color: #ef4444;
  color: #b91c1c;
}

/* Main Content */
.main-content {
  border-radius: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visible {
  flex-grow: 9;
  border: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
}

/* Table Container and Scrollbar Styles */
.table-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  background: white;
}

/* Hide scrollbar by default */
.table-container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Show scrollbar only when scrolling */
.table-container:hover::-webkit-scrollbar-thumb,
.table-container:focus::-webkit-scrollbar-thumb,
.table-container:focus-within::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.table-container:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.table-container:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  position: sticky;
  top: 0;
  background-color: #f9fafb;
  z-index: 1;
}

th:first-child {
  border-top-left-radius: 12px;
}

th:last-child {
  border-top-right-radius: 12px;
}

th {
  background-color: #f9fafb;
  font-weight: 600;
  color: #4b5563;
  cursor: pointer;
  padding: 1rem;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #e5e7eb;
}

td {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Hover states */
tbody tr:hover {
  background-color: #f3f4f6;
}

th:hover {
  background-color: #f3f4f6;
}

/* Sort indicators */
.sort-asc {
  background-color: #dbeafe;
}

.sort-desc {
  background-color: #fee2e2;
}

/* Rarity Toggles */
.rarity-toggles {
  padding: 1rem;
}

.rarity-toggles h4 {
  margin-bottom: 0.5rem;
  color: #374151;
}

.rarity-toggles label {
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

/* Minimum Price Input */
.price-filter {
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.minimum-price input {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  width: 10px;
}

.price-filter input {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  width: 150px;
}
/* Utility Classes */
.hidden {
  display: none;
}

/* Loading and Error States */
.loading-spinner {
  display: grid;
  place-items: center;
  height: 100px;
  color: #6b7280;
}

.error-message {
  color: #dc2626;
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #f87171;
  border-radius: 6px;
  background-color: #fee2e2;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.logout-button {
  position: fixed;
  width:auto;
  top: 20px;
  right: 20px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #c82333;
}

.logout-button:active {
  transform: scale(0.98);
}
